import { Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useFetch from '../hooks/useFetch';

import Home from './Home';
import Pages from './Pages';
import Cards from './Cards';
import Search from './Search';

const theme = createTheme();

const App = () => {
  useFetch('menus', 'menus');
  useFetch('cards', 'cards');

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pages" element={<Pages />} />
        <Route path="/cards" element={<Cards />} />
        <Route path="/search" element={<Search />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
