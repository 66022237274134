import { useEffect } from 'react';
import useStore from './useStore';

const useFetch = (url, type) => {
  const { menus, cards, setMenus, setCards, setError } = useStore();
  const data = type === 'menus' ? menus : cards;
  const setData = type === 'menus' ? setMenus : setCards;

  useEffect(() => {
    const targetUrl = './store/' + url + '/';

    const fetchData = async () => {
      try {
        const response = await fetch(targetUrl);
        const data = await response.json();
        setData(data);
      } catch (error) {
        setError(error);
      }
    };

    if (!data) {
      fetchData();
    }
  }, [url, data, setData, setError]);
};

export default useFetch;
